<template>
    <b-card>
        <b-form v-on:submit.prevent>
            <b-row>
                <b-col cols="12">
                    <b-form-group
                    label="Roles Name"
                    label-for="rolesName"
                    label-cols-md="2"
                    >
                        <b-form-input
                            id="rolesName"
                            placeholder="Roles Name"
                            v-model="form.roles_name"
                            required
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <!-- PERMISSION TABLE -->
                    <b-card
                    no-body
                    class="border mt-1"
                    >
                        <b-card-header class="p-1">
                            <b-card-title class="font-medium-2">
                            <feather-icon
                                icon="LockIcon"
                                size="18"
                            />
                            <span class="align-middle ml-50">Permission</span>
                            </b-card-title>
                        </b-card-header>
                        <div class="container">
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="User">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Profile
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="27"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="28"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="29"
                                            >
                                            Update Password
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="30"
                                            >
                                            Image Store
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="31"
                                            >
                                            Push Notification
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Role
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="2"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[1, 3, 6]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="4"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="5"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Master">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Position
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="23"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[22, 24]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="25"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="26"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Organization
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="8"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[7, 9]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="10"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="11"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Division
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="13"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[12, 14]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="15"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="16"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Department
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="18"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[17, 19]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="20"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="21"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Status
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="106"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[105, 107]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="108"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="109"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            PTKP
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="101"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[100, 102]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="103"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="104"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="HR">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Employee
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[33, 40, 42, 44]"
                                            checked
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[32, 34]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[35, 41, 43, 45]"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="36"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="37"
                                            >
                                            Export
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="38"
                                            >
                                            Import
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="39"
                                            >
                                            Image Store
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Attendance
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[48, 49, 54]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="52"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="50"
                                            >
                                            Check In &amp; Out
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="51"
                                            >
                                            Add Manual
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="53"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="55"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Overtime
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="58"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[56, 57, 61]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="59"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="60"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="62"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Permit
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="65"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[63, 64, 68]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="66"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="67"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="69"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Reimbursement
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="72"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[70, 71, 75]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="73"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="74"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="76"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Cash Advance
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="79"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[77, 78, 83]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="81"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[80, 82]"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="84"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Payroll
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[110, 115]"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[111, 114, 116]"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[112, 117]"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="[113, 118]"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Company">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Profile
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="46"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="47"
                                            >
                                            Read
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                        </div>
                    </b-card>
                </b-col>
                <!-- submit and reset -->
                <b-col md="12" class="mt-2">
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click="addRoles()"
                    >
                    Submit
                    </b-button>
                    <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                    >
                    Reset
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BTable, BCardHeader, BCardTitle, BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
    components: {
        ToastificationContent,
        AppCollapse,
        AppCollapseItem,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BListGroupItem,
        BAvatar,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                roles_name: ''
            },
            selected: [],
            permission: [],
            errMessage: ''
        }
    },
    methods: {
        addRoles() {
            let $permissions = this.selected;
            // console.log($permissions);

            var i;
            for(i=0; i < $permissions.length; i++) {

                var $permission = {
                    permission: $permissions[i]
                };
                this.permission.push($permission);
            };
            // console.log(this.permission);

            this.$http
            .post("user/roles/add", {
                "name": this.form.roles_name,
                "permission": this.permission
            })
            .then((response) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success add Role',
                        variant: 'success',
                    },
                })
                location.href = "/settings/roles"
                // console.log(response.data.data)
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
    },
}
</script>